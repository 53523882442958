import React, {useEffect, useMemo, useState} from 'react';
import {CartItemDataHook} from '../../CartItem';
import {CounterSize, Popover} from 'wix-ui-tpa';
import {Counter} from 'wix-ui-tpa/cssVars';
import {debounce} from '../../../../../../domain/lib/debounce';
import {getMaxItemQuantity, getIsQuantityErrorNeeded, getIsOutOfStock} from '../../../../../../domain/utils/itemUtils';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {ICartItem} from '../../../../../../types/app.types';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {classes} from './Quantity.st.css';

export const Quantity = ({item}: {item: ICartItem}) => {
  const {t} = useTranslation();
  const {updateItemQuantity, shouldPresentTooltipWithoutNumber} = useControllerProps().cartStore;
  const debouncedUpdateItemQuantity = useMemo(() => debounce(updateItemQuantity), [updateItemQuantity]);
  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [hasQuantityError, sethasQuantityError] = useState<boolean>(false);
  const handleChange = (value: string) => {
    const isQuantityErrorNeeded = getIsQuantityErrorNeeded(item, +value);
    if (isQuantityErrorNeeded) {
      triggerQuantityErrorIfNeeded();
      return;
    }
    setQuantity(+value);
    void debouncedUpdateItemQuantity(item.cartItemId, +value, item.product.id);
  };

  const triggerQuantityErrorIfNeeded = () => {
    sethasQuantityError(true);
    setTimeout(() => {
      sethasQuantityError(false);
    }, 2e3);
  };
  useEffect(
    () => {
      setQuantity(item.quantity);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [item.quantity]
  );

  return (
    <Popover
      data-hook={CartItemDataHook.QuantityErrorTooltip}
      className={classes.popover}
      width={165}
      shown={hasQuantityError}
      placement="top">
      <Popover.Element>
        <Counter
          disabled={getIsOutOfStock(item)}
          className={classes.quantity}
          size={CounterSize.xSmall}
          error={hasQuantityError}
          value={quantity}
          step={1}
          data-hook={CartItemDataHook.Quantity}
          onChange={handleChange}
          incrementAriaLabel={t('cart.sr.addQty')}
          decrementAriaLabel={t('cart.sr.removeQty')}
          inputAriaLabel={t('cart.sr.chooseQty')}
          aria-label={t('cart.sr.quantity')}
          min={1}
          max={getMaxItemQuantity(item)}
        />
      </Popover.Element>
      <Popover.Content>
        <span role="alert">
          {shouldPresentTooltipWithoutNumber
            ? t('cart.errors.item_quantity_exceed_inventory_no_number')
            : t('cart.errors.item_quantity_exceed_inventory', {
                itemsAmount: getMaxItemQuantity(item),
              } as object)}
        </span>
      </Popover.Content>
    </Popover>
  );
};
