import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../types/app.types';
import s from '../../CartItem.scss';
import {ItemLink} from '../ItemLink/ItemLink';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';

export const Name = ({item}: {item: ICartItem}) => {
  const {
    cartStore: {makeCartItemNameLink},
  } = useControllerProps();
  const {name} = item.product;

  if (makeCartItemNameLink) {
    return (
      <h3 data-hook={CartItemDataHook.Name} className={s.productName}>
        <ItemLink item={item}>{name}</ItemLink>
      </h3>
    );
  }

  return (
    <h3 data-hook={CartItemDataHook.Name} className={s.productName}>
      {name}
    </h3>
  );
};
