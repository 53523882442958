import React, {useRef} from 'react';
import {BuyerNote} from '../../Components/BuyerNote/BuyerNote';
import {CartItems} from '../../../../../common/components/CartItems/CartItems';
import {Coupon} from '../../Components/Coupon/Coupon';
import {Headline} from '../../Components/Headline/Headline';
import {OrderSummary} from '../../Components/OrderSummary/OrderSummary';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CartBody.scss';
import {CheckoutButtons} from '../../Components/CheckoutButtons/CheckoutButtons';
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor';
import {CartScreenReaderMessage} from './CartScreenReaderMessage/CartScreenReaderMessage';
import {MinimumOrderAmount} from '../../Components/MinimumOrderAmount/MinimumOrderAmount';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {Violations} from '../../../../../common/components/Violations/Violations';
import {useIsAfterHydration} from '../../../../../hooks/useIsAfterHydration';

export const CartBody = () => {
  const {t} = useTranslation();

  const {
    shouldShowCoupon,
    shouldShowBuyerNote,
    shouldShowMinimumOrderAmount,
    shouldShowTopCheckoutButtonInMobile,
    shouldShowSecureCheckout,
    shouldDisplayViolations,
  } = useControllerProps().cartStore;
  const {isMobile, isSSR} = useEnvironment();
  const cartBodyRef = useRef<HTMLDivElement>(null);

  let isStudioOrEditorXSite = false;

  const isAfterHydration = useIsAfterHydration();

  /* istanbul ignore next */
  if (!isSSR) {
    const editorBrand = (window as any)?.commonConfig?.brand;
    isStudioOrEditorXSite = editorBrand === 'editorx' || editorBrand === 'studio';
  }

  let mainClassName = s.main;

  /* istanbul ignore next */
  if (cartBodyRef.current?.clientWidth <= 920) {
    mainClassName = 'cartBodyMainOneColumn';
  }

  return (
    <main className={mainClassName} ref={cartBodyRef}>
      <section className={s.cart}>
        {isMobile && shouldShowTopCheckoutButtonInMobile && !isStudioOrEditorXSite && isAfterHydration && (
          <CheckoutButtons isOnTop />
        )}
        <Headline />
        <CartItems showQuantityBySettings showThumbnailBySettings shouldShowItemInfoBySettings />
        <footer className={s.footer}>
          {shouldShowCoupon && (
            <div className={s.coupon}>
              <Coupon />
            </div>
          )}
          {shouldShowBuyerNote && (
            <div className={s.buyerNote}>
              <BuyerNote />
            </div>
          )}
        </footer>
        <CartScreenReaderMessage />
      </section>
      <aside className={s.summary}>
        <OrderSummary />
        {shouldDisplayViolations && <Violations />}
        {shouldShowMinimumOrderAmount && <MinimumOrderAmount />}
        <CheckoutButtons />
        {shouldShowSecureCheckout && <SecureCheckout text={t('cart.orderSummary.secureCheckout.label')} />}
      </aside>
    </main>
  );
};
