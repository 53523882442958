import React from 'react';
import {Loader as LoaderAnimation} from '@wix/wixstores-client-common-components';
import s from './Loader.scss';

export const Loader = /* istanbul ignore next */ () => {
  /* istanbul ignore next */
  return (
    <div className={s.loader}>
      <LoaderAnimation />
    </div>
  );
};
