/* eslint-disable sonarjs/no-small-switch */
import React from 'react';
import {WidgetProps} from '@wix/yoshi-flow-editor';
import {IControllerProps} from '../../../types/app.types';
import CartApp from './CartApp/CartApp';
import {GeneralError} from '../../../common/components/GeneralError/GeneralError';
import {ControllerContext} from '../../../domain/controllers/ControllerContext';
import {withExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';

const CartAppRoot = (controllerProps: WidgetProps<IControllerProps>) => {
  switch (true) {
    case controllerProps.generalError:
      return <GeneralError />;
    default:
      return (
        <ControllerContext value={controllerProps}>
          <CartApp host={controllerProps.host} ravenUserContextOverrides={{}} />
        </ControllerContext>
      );
  }
};

export default withExpressCheckoutButtons(CartAppRoot);
